import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Routes, Link, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import PageRoutes from './components/PageRoutes.js'

const routes = (
  <PageRoutes />
);

ReactDOM.render(routes, document.getElementById('root'));
