import React from 'react';
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";

class PageResults extends React.Component {
  
  STATE_GETTING_RESULTS = 0;
  STATE_COMPLETED = 1;
  STATE_ERROR = 2;
  
  constructor(props) {
    super(props);
    this.state = { pageState : this.STATE_GETTING_RESULTS,
                   processingErrorMessage : '',
                   url : '',
                   parsedSourceProducts : [],
                   totalMatchedResultProducts : 0,
                   matchedResultProducts : []
                 };
  };

  async componentDidMount() {
    const windowUrl = window.location.href;
    const m = /^.*?\/results\/(.*)$/.exec(windowUrl);
    if (m) {
      const processUrl = m[1];
      const processUrlDecoded = decodeURIComponent(processUrl);
      this.setState( { url : processUrlDecoded } );

      const postData = { url : processUrl };
      
      const options = {
        method : 'POST',
        headers : { 'Content-Type' : 'application/json' },
        body : JSON.stringify(postData)
      };

      try { 
        const res = await fetch('https://experiment.getlocal.ie/api/process', options);
        const resData = await res.json();
        //alert(JSON.stringify(resData));

        if (!resData.is_success) {
          const errorMessage = resData.err_msg;
          this.setState( { pageState : this.STATE_ERROR,
                          processingErrorMessage : errorMessage
                       } );
        }
        else {
          // Looking good
          this.setState( { parsedSourceProducts : resData.parsed_source_products,
                           totalMatchedResultProducts : resData.total_matched_result_products,
                           matchedResultProducts : resData.matched_result_products,
                           pageState : this.STATE_COMPLETED
                         } );
        }
      }
      catch (e) {
        this.setState( { pageState : this.STATE_ERROR,
                        processingErrorMessage : JSON.stringify(e)
                     } );
      }
    }
    else {
      this.setState( { pageState : this.STATE_ERROR,
                       processingErrorMessage : 'Could not find any supplied test Url in our page Url'
                     } );
    }
  };

  render() {

    const navHtml = (
      <nav className="sl_nav">
        <div className="nav-wrapper">
          <a href="#" className="brand-logo"><img src="/gl_logo_w230.png" className="sl_navlogo" alt="GetLocal Logo" /></a>
          <div className="right-align">
            <a href="/" className="sl_nav_newtestmenulink">New Test</a>
          </div>
        </div>
      </nav>
    );

    const urlBarHtml = (
      <div className="sl_nav_urlbar">
        Test Url : <a href={ this.state.url } target="_otherwindow">{ this.state.url }</a>
      </div>
    );

    if (this.state.pageState == this.STATE_GETTING_RESULTS) {
      return (
        <div>
          { navHtml }
          { urlBarHtml }
          <div className="sl_pagespacer"></div>
          <div className="center-align">
            <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ 'fontSize' : '88px', 'color' : 'grey' }}></i>
            <p>Processing, please wait...</p>
          </div>
        </div>
      );
    }
    else if (this.state.pageState == this.STATE_COMPLETED) {
      let parsedSourceProducts = this.state.parsedSourceProducts;
      let matchedResultProducts = this.state.matchedResultProducts;
      const numberParsedSourceProducts = parsedSourceProducts.length;
      const numberMatchedResultProducts = matchedResultProducts.length;
      const isAnyParsedSourceProducts = (numberParsedSourceProducts>0);
      const isAnyMatchedResultProducts = (numberMatchedResultProducts>0);

      // Pre-process parsed source product records
      /** (Moved to backend)
      for (let i=0;i<parsedSourceProducts.length;i++) {
        if (i==0) {
          parsedSourceProducts[i].isFirstProduct = true;
        }
        else {
          parsedSourceProducts[i].isFirstProduct = false;
        }
        if (parsedSourceProducts[i].hasOwnProperty('images') &&
            parsedSourceProducts[i].images.length > 0) {
          parsedSourceProducts[i].hasImage = true;
          parsedSourceProducts[i].firstImage = parsedSourceProducts[i].images[0];
        }
        else {
          parsedSourceProducts[i].hasImage = true;
          parsedSourceProducts[i].firstImage = parsedSourceProducts[i].images[0];
        }
      }
      **/
      
      for (let i=0;i<matchedResultProducts.length;i++) {
        matchedResultProducts[i].product_href = 'https://www.getlocal.ie/product/'+matchedResultProducts[i].product_shortcode+'/'+matchedResultProducts[i].fcrmarketplace_pretty_product_name;
        matchedResultProducts[i].store_href = 'https://www.getlocal.ie/store/'+matchedResultProducts[i].fcrmarketplace_pretty_store_name;
       
        // GetLocal local Image Url 
        const m = /^https?\:\/\/(.*?)\/.*$/i.exec(matchedResultProducts[i].product_url);
        if (m) {
          const domain = m[1];
          const flattened_image_url = matchedResultProducts[i].product_imageurl.replace(/[\W_]+/g, '_');
          const local_stored_image_url = 'https://dlvnjk3a1hoj2.cloudfront.net/gli/'+domain+'/thumbnail/'+flattened_image_url+'.jpg';
          matchedResultProducts[i].product_imageurl = local_stored_image_url;
        }
      }

      return (
        <div>
          { navHtml }
          { urlBarHtml }
          <div className="sl_pagespacersmall"></div>
          <div className="row">

            <div className="col s12 m6 l6">
              <div className="card sl_card">
                <div className="card-content">
                  <span className="card-title">Detected Page Products</span>
                  { parsedSourceProducts.map(product  => (
                    <div className="row valign-wrapper" style={{ backgroundColor : product.isFirstProduct ? '#f0f0ff' : 'white', padding : '5px' }}>
                      <div className="col s4">
                        { product.hasImage &&
                          <img className="sl_productimage" src={ product.firstImage } style={{ width : '120px' }} />
                        }
                      </div>
                      <div className="col s8">
                        <b>{ product.name }</b><br />
                        { product.description }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col s12 m6 l6">
              <div className="card sl_card">
                <div className="card-content">
                  <span className="card-title">Matching GetLocal Products (Total: { this.state.totalMatchedResultProducts })</span>
                  { matchedResultProducts.map(product  => (
                    <div className="row valign-wrapper">
                      <div className="col s4">
                        <img className="sl_productimage" src={ product.product_imageurl } style={{ width : '120px' }} />
                      </div>
                      <div className="col s8">
                        <b><a href={ product.product_href } target="_otherwindow">{product.product_name}</a></b><br />
                        {product.product_description}<br />
                        <b>From <a href={ product.store_href } target="_otherwindow">{product.gpi_businessname}</a></b>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
                        
                    





          </div>
        </div>
      );
    }
    else {
      return (
        <div>
          { navHtml }
          { urlBarHtml }
          <div className="sl_pagespacer"></div>
          <div className="center-align">
            <i className="fa fa-frown-o fa-rotate-30" aria-hidden="true" style={{ 'fontSize' : '160px', 'color' : '#e74c3c', fontWeight : 'bold' }}></i>
            <p>ERROR: { this.state.processingErrorMessage }</p>
          </div>
        </div>
      );
    }
  };

}


export default PageResults;

