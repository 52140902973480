import React from 'react';
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";

class Page404 extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    return (
      <div>
        <nav className="sl_nav">
          <div className="nav-wrapper">
            <a href="#" className="brand-logo"><img src="/gl_logo_w230.png" className="sl_navlogo" alt="GetLocal Logo" /></a>
            <div className="right-align">
              <a href="/" className="sl_nav_newtestmenulink">New Test</a>
            </div>
          </div>
        </nav>

        <h1>404 Page Not Found</h1>
      </div>
    );
  };

}


export default Page404;

