import React from 'react';
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";

class PageIndex extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      url : ''
    };
  };

  change_url_value = (event) => {
    const element_id = event.target.id;
    const element_value = event.target.value;
    
    if (element_id == 'url') {
      this.setState( { url : element_value } );
    }
  };

  submit_url_clicked = () => {
    const url = this.state.url;
    if (!url.toLowerCase().startsWith('http://') && !url.toLowerCase().startsWith('https://')) {
      alert('URL must start with http:// or https://');
    }
    else {
      //alert('Submit Clicked');
      const url_escaped = encodeURIComponent(url);
      //alert(url_escaped);
      const redir_url = '/results/'+url_escaped;
      window.location.href = redir_url;
    }
  };

  render() {
    return (
      <div>
        <nav className="sl_nav">
          <div className="nav-wrapper">
            <a href="#" className="brand-logo"><img src="/gl_logo_w230.png" className="sl_navlogo" alt="GetLocal Logo" /></a>
          </div>
        </nav>

        <div className="sl_pagespacer"></div>





        <div className="row">
          <div className="col s0 m2 l3"></div>
            <div className="col s12 m8 l6">
              <div className="card sl_card">
                <div className="card-content sl_cardcontent">
                  <div className="row">
                    <form className="col s12">
                      <div className="input-field col s12">
                        <input id="url" type="text" className="validate" placeholder="Enter URL containing structured product data" value={this.state.url} onChange={(event) => this.change_url_value(event)} />
                        <label className="active" for="url">URL</label>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card-action right-align">
                  <a className="waves-effect waves-light btn" onClick={this.submit_url_clicked}><i className="material-icons left">search</i>Search</a>
                </div>
              </div>
            </div>
          <div className="col s0 m2 l3"></div>
        </div>
      </div>
    );
  };

};

export default PageIndex;

