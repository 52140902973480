import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Routes, Link, BrowserRouter as Router } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import PageIndex from './PageIndex';
import PageResults from './PageResults';
import Page404 from './Page404';

import './styles.css';

class PageRoutes extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="RouteContainer">
      <Router>
        <Routes>
          <Route exact path="/" element={<PageIndex />} />
          <Route path="/results/*" element={<PageResults />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
      </div>
    );
  }
};

export default PageRoutes;

